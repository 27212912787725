






















import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  useRoute,
  useStore,
  watchEffect,
} from '@nuxtjs/composition-api'
import TabsHead from '@/components/Tabs/TabsHead.vue'
import { Tab } from '@/contracts/Tabs'

export default defineComponent({
  components: { TabsHead },
  props: {
    initialTab: {
      type: Object as PropType<Tab>,
      default: null,
    },
    list: {
      type: Array as PropType<Tab[]>,
      default: () => [],
    },
    isVertical: {
      type: Boolean,
      default: false,
    },
    useMobileShowAll: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const value = ref<string | null>(null)
    const store = useStore()
    const route = useRoute()
    const isMobile = computed<boolean>(
      (): boolean => store.getters['mediaQuery/isMobileMq']
    )

    const currentItem = computed<string>(() => {
      if (!props.list.length) return ''
      return props.list!.find((listItem) => listItem.value === value.value)
        .value
    })

    const onInput = (evt: string) => {
      emit('input', evt)
    }

    watchEffect(() => {
      if (props.list && Array.isArray(props.list) && props.list.length) {
        const tabWithId = props.list.find(
          (tab) => `#${tab.id}` === route.value.hash
        )
        if (tabWithId) {
          value.value = tabWithId.value
        } else {
          const [{ value: val = null }] = props.list || []
          value.value = val
        }
      }
    })
    // Есть проблема с компонентом, в prod режиме при mount\watch выскакивает ошибка, если активный таб !== первому элементу, сложно дебажить
    onMounted(() => {
      setTimeout(() => {
        if (props.initialTab) {
          value.value = props.initialTab.value
        }
      }, 0)
    })

    return {
      isMobile,
      value,
      currentItem,
      onInput,
    }
  },
})
