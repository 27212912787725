














import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { defineComponent } from '@nuxtjs/composition-api'
import CardFaq from '@/components/Cards/CardFaq.vue'

@Component
class CardFaqList extends Vue {
  @Prop() readonly list: ReadonlyArray<any> = []
  static options: any
}

export default defineComponent({
  name: 'CardsFaqList',
  components: {
    CardFaq,
  },
  props: CardFaqList.options.props,
  setup() {},
})
