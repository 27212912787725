




































import { Vue, Component, Prop } from 'nuxt-property-decorator'
import {
  defineComponent,
  nextTick,
  onMounted,
  ref,
  useRoute,
} from '@nuxtjs/composition-api'
import { VueSlideToggle } from 'vue-slide-toggle'
import BlockText from '@/components/BlockText'

@Component
class CardFaq extends Vue {
  @Prop() readonly title: String = ''
  @Prop() readonly description: String = ''
  @Prop() readonly duration: Number = 300
  static options: any
}

export default defineComponent({
  name: 'CardFaq',
  components: { BlockText, VueSlideToggle },
  props: CardFaq.options.props,
  setup(props) {
    const route = useRoute()
    const card = ref<HTMLDivElement>()
    const value = ref(Boolean(props.title === route.value.query.answer))

    const onClick = () => {
      value.value = !value.value
    }

    onMounted(() => {
      nextTick(() => {
        if (value.value) {
          const { top, height } = card.value?.getBoundingClientRect()
          window.scrollTo({
            top: top - height,
            behavior: 'smooth',
          })
        }
      })
    })

    return {
      onClick,
      card,
      value,
    }
  },
})
