import { computed, ComputedRef } from '@nuxtjs/composition-api'
const ICON_TYPES = ['DOC', 'DOCX', 'RTF', 'PDF', 'ZIP', 'RAR']

export const getIconNameByFileType = (type: string): ComputedRef<string> =>
  computed(() => {
    if (type === 'DOCX') {
      return 'DOC'
    }
    if (ICON_TYPES.includes(type)) {
      return type
    }
    return 'default_attach'
  })
