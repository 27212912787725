













import { Vue, Component, Prop } from 'nuxt-property-decorator'
import { defineComponent } from '@nuxtjs/composition-api'
import CardDocument from '../Cards/CardDocument.vue'
import { IDocumentItem } from '@/contracts/DocumentItem'

@Component
class CardDocumentList extends Vue {
  @Prop() readonly list: ReadonlyArray<IDocumentItem> = []
  @Prop() readonly titleCards: String = ''
  static options: any
}

export default defineComponent({
  name: 'CardDocumentList',
  components: { CardDocument },
  props: CardDocumentList.options.props,
})
