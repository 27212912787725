
















































import { Vue, Component, Prop } from 'nuxt-property-decorator'
import { defineComponent } from '@nuxtjs/composition-api'
import { getIconNameByFileType } from '../../composition/fileTypeIcon'

@Component
class CardDocument extends Vue {
  @Prop() readonly title: String = ''
  @Prop() readonly url: String = ''
  @Prop() readonly regNumber: String = ''
  @Prop() readonly showDate: string = ''
  @Prop() readonly revisionDate: string = ''
  @Prop() readonly pdfWeight: String = ''
  @Prop() readonly dateText: String = ''
  @Prop() readonly description: String = ''
  @Prop() readonly contentType: String = ''
  static options: any
}

const CardDocumentProps = CardDocument.options.props

export default defineComponent({
  name: 'CardDocument',
  props: { ...CardDocumentProps },
  setup(props) {
    const compIconType = getIconNameByFileType(props.contentType)
    return {
      compIconType,
    }
  },
})
